.answer-list-item {
    height: 65px;
    padding-left: 30px;
    padding-bottom: 10px;
    padding-top: 10px;
    cursor: pointer;
    margin: auto;
    display: flex;
    align-items: center;
}

.answer-list-item .sp-icon {
    display: flex;
    align-items: center;
}

.question {
    padding-top: 20px;
    padding-bottom: 10px;
}

.result-item {
    margin-top: 10px;
    margin-bottom: 10px;
}

.answer-list-item:hover {
    background-color: lightgray;
    color: #333;
}

.answer-selected {
    background-color: lightgray;
}

.answer-unselected {
    background-color: white;
}

.course-brand-category-image {
    height: 40vh;
    width: 100%;
    background-size: cover;
    background-position: center center;
}

.correct-icon-color {
    color: greenyellow;
}

.wrong-icon-color {
    color: red;
}

.topic-list-item-content {
    background-color: white;
    height: 200px;
    line-height: 200px;
}

.topic-list-item-content > .sp-icon {
    color: #929292;
    vertical-align: middle;
}

.topic-list-item {
    border: 1px solid #929292;
    margin-top: 20px;
    cursor: pointer;
}

.topic-list-item-header {
    background-color: #FBF9F9;
    height: 50px;
    line-height: 50px;
    overflow: hidden;
}

.topic-list-item-header > .sp-icon {
    vertical-align: middle;
}

.topic-list-item-header > .sp-uncheck {
    color: #929292;
}

.topic-list-item-header > .sp-check {
    color: red;
}

.course-header {
    color: white;
}

.course-header-texts-container {
    position: absolute;
    top: 40%;
    left: 45%;
    text-align: center;
}

.course-category-image {
    position: absolute;
    left: 20px;
    top: 20px;
    width: 60px;
}

.course-header-points-container {
    position: absolute;
    right: 20px;
    bottom: 20px;
}

.sp-menu > .sp-logo {
    color: white;
}

.certificate-retailer-list-item {
    padding: 20px;
}

.text-color-gray {
    color: dimgray;
}

.text-color-black {
    color: black;
}

.sp-no-results-logo {
    color: black;
    text-align: center;
}

.tab-content {
    width: 100%;
}

.leaderboard-filter-form > input {
    margin: 20px;
}

.leaderboard-filter-form-input {
    margin-bottom: 10px;
    margin-top: 10px;
    padding: 10px;
    width: 100%;
    border: 0;
    background-color: lightgray;
}

.switch {
    position: relative;
    display: inline-block;
    width: 60px;
    height: 34px;
}

.switch input {
    display: none;
}

.slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    -webkit-transition: .4s;
    transition: .4s;
}

.slider:before {
    position: absolute;
    content: "";
    height: 26px;
    width: 26px;
    left: 4px;
    bottom: 4px;
    background-color: white;
    -webkit-transition: .4s;
    transition: .4s;
}

input:checked + .slider {
    background-color: greenyellow;
}

input:focus + .slider {
    box-shadow: 0 0 1px greenyellow;
}

input:checked + .slider:before {
    -webkit-transform: translateX(26px);
    -ms-transform: translateX(26px);
    transform: translateX(26px);
}

/* Rounded sliders */
.slider.round {
    border-radius: 34px;
}

.slider.round:before {
    border-radius: 50%;
}

.leaderboard-header-data {
    margin-top: 20px;
}

.nav-tabs > li {
    cursor: pointer;
}

.sp-filter {
    cursor: pointer;
}

/*--BEGIN FILTER COMPONENT*/
.filter-group-content > .row {
    cursor: pointer;
    border-bottom: 1px solid lightgrey;
    margin-bottom: 15px;
}

.filter-group-content > .row :hover {
    background-color: lightgray;
}

div.sp-check {
    color: red;
}

div.sp-uncheck {
    color: black;
}

.filter-group-heading {
    font-weight: bold;
    margin: 20px;
}

.filter-group-content {
    color: #4d4d4d;
    margin: 20px;
}

.filter-panel {
    color: black;
    height: 200px;
    padding: 10px;
    overflow-y: scroll;
    min-height: 400px;
    overflow-x: hidden;
}

.clear-all {
    text-align: right;
    color: red;
    padding-right: 20px;
    margin-right: 20px;
    cursor: pointer;
}

.sp-app .opensans300 {
    font-family: 'OpenSans300', sans-serif;
}

.sp-app img {
    border: none;
}

.sp-app .sellpro-menu-logo i {
    font-size: 40px;
    padding-left: 10px;
}

/*--END FILTER COMPONENT*/