@font-face {
    font-family: icomoon;
    font-style: normal;
    font-weight: normal;
    src: url("/assets/css/icons/icomoon.ttf") format("truetype");
}

@font-face {
    font-family: icomoon2;
    font-style: normal;
    font-weight: normal;
    src: url("/assets/css/icons/icomoon2.ttf") format("truetype");
}

/*EXAMPLE*/
/*<i class="sp-icon sp-large sp-home"></i>*/
/*<i class="sp-icon sp-large {{icon.type}}"></i>*/
/*EXAMPLE*/

.sp-icon {
    font-family: 'icomoon';
    font-style: normal;
    display: inline-block;
    font-size: 12px;
}

.sp-icon-2 {
    font-family: 'icomoon2';
    font-style: normal;
    display: inline-block;
    font-size: 12px;
}

/*size*/
.sp-icon.sp-small, .sp-icon-2.sp-small {
    font-size: 14px;
}
.sp-icon.sp-medium, .sp-icon-2.sp-medium {
    font-size: 18px;
}
.sp-icon.sp-large, .sp-icon-2.sp-large {
    font-size: 24px;
}
.sp-icon.sp-xlarge, .sp-icon-2.sp-xlarge {
    font-size: 30px;
}
.sp-icon.sp-xxlarge, .sp-icon-2.sp-xxlarge {
    font-size: 60px;
}
.sp-icon.sp-xxxlarge, .sp-icon-2.sp-xxxlarge{
    font-size: 120px;
}
/*size*/


/*icons*/
.sp-qrscan:before {
    content: '>';
}
.sp-qrcode:before {
    content: '@';
}
.sp-lock:before {
    content: '?';
}
.sp-email:before {
    content: 'A';
}
.sp-password:before {
    content: 'B';
}
.sp-video:before {
    content: 'C';
}
.sp-pdf:before {
    content: 'D';
}
.sp-image:before {
    content: 'E';
}
.sp-link:before {
     content: 'F';
 }
.sp-award-lock:before {
    content: 'G';
}
.sp-referral:before {
    content: 'H';
}
.sp-product-categories:before {
    content: 'I';
}
.sp-store:before {
    content: 'J';
}
.sp-job-category:before {
    content: 'K';
}
.sp-download-inactive:before {
    content: 'L';
}
.sp-company:before {
    content: 'M';
}
.sp-facebook:before {
    content: 'N';
}
.sp-arrow-right:before {
    content: 'O';
}
.sp-arrow-left:before {
    content: 'P';
}
.sp-arrow-up:before {
    content: 'Q';
}
.sp-arrow-down:before {
    content: 'R';
}
.sp-pin-inactive:before {
    content: 'S';
}
.sp-language:before {
    content: 'T';
}
.sp-filter:before {
    content: 'U';
}
.sp-filter-set:before {
    content: '{';
}
.sp-check:before {
    content: 'V';
}

.sp-uncheck:before {
    content: 'W';
}
.sp-check-cross:before {
    content: 'X';
}
.sp-tick:before {
    content: 'Y';
}
.sp-award-unlock:before {
    content: 'Z';
}
.sp-search:before {
    content: '[';
}
.sp-downloading:before {
    content: ']';
}
.sp-home:before {
    content: 'a';
}
.sp-courses:before {
    content: 'b';
}
.sp-reference:before {
    content: 'c';
}
.sp-pin-active:before {
    content: 'd';
}
.sp-events:before {
    content: 'e';
}
.sp-leaderboard:before {
     content: 'f';
 }
.sp-share:before {
    content: 'g';
}
.sp-settings:before {
    content: 'h';
}
.sp-toolbox:before {
    content: 'i';
}
.sp-playzone:before {
    content: 'j';
}
.sp-proshop:before {
    content: '7';
}
.sp-tile-share:before {
    content: 'k';
}
.sp-ufo-1:before {
    content: 'l';
}
.sp-ufo-2:before {
    content: 'm';
}
.sp-tile-home:before {
    content: 'n';
}
.sp-tile-courses:before {
    content: 'o';
}
.sp-tile-toolbox:before {
    content: 'p';
}
.sp-tile-references:before {
    content: 'q';
}
.sp-tile-events:before {
    content: 'r';
}
.sp-tile-notifications:before {
    content: '8';
}
.sp-sync:before {
    content: 't';
}
.sp-profile:before {
    content: 'u';
}
.sp-certificates:before {
    content: 'v';
}
.sp-sellpoints:before {
    content: 'w';
}
.sp-more:before {
    content: 'x';
}
.sp-logo:before {
    content: 'y';
}
.sp-awards:before {
    content: 'z';
}
.sp-filled-circle:before {
    content: '^';
}

.sp-logout:before {
    content: '9';
}

.sp-mall:before {
    content: '1';
}

.sp-state:before {
    content: '0';
}
/*icons*/


/*icons 2*/
.sp-sellpoints-2:before {
    content: '7';
}

.sp-progress-2:before {
    content: '5';
}

.sp-time-2:before {
    content: '6';
}




/*icons 2*/
