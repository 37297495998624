@import "icons/icons.css";
@import "iapp.css";
@import "bapp.css";
@import "dapp.css";

@font-face {
    font-family: OpenSans300;
    font-style: normal;
    font-weight: normal;
    src: url("/assets/fonts/Open-Sans-300.ttf") format("truetype");
}

.background-white {
    background: #fff;
}

@font-face {
    font-family: OpenSans600;
    font-style: normal;
    font-weight: normal;
    src: url("/assets/fonts/Open-Sans-600.ttf") format("truetype");
}

@font-face {
    font-family: OpenSansBold;
    font-style: normal;
    font-weight: bold;
    src: url("/assets/fonts/Open-Sans-800.ttf") format("truetype");
}

html, body {
    background: #000;
    height: 100%;
    font-family: "OpenSans600", sans-serif;
    color: #1c1c1c;
    overflow-x: hidden;
}

a {
    color: #fff;
}

.sp-app .sp-white-background {
    background: white;
}

.sp-app .sp-menu.list-group {
    background: none;
    border: none;
}

.sp-app .sp-menu .list-group-item, .sp-app .list-group-item {
    border: none;
    background: none;
}

.sp-app .sp-menu a.list-group-item {
    border: none;
    border-left: 2px solid transparent;
    background: none;
    color: #C8C8C8;
    padding: 5px 15px;
    font-size: 11px;
}

.sp-app .sp-menu a.list-group-item.selected {
    border-left: 2px solid white;
    color: #fff;
}

.sp-app .sp-menu a.list-group-item i {
    min-width: 45px;
    text-align: center;
    text-transform: none;
    color: #C8C8C8;
    vertical-align: middle;
}

a:hover {
    color: #fff;
    text-decoration: none;
    animation-name: sp-opacity;
    animation-duration: 0.3s;
    animation-fill-mode: forwards;
}

.sp-register-button .text-muted {
    padding-left: 3px;
}

.sp-register-button {
    display: block;
}

.sp-register-button select, .sp-register-button input, .sp-register-button button {
    display: block;
    border: none;
    background: none;
    box-shadow: none;
    text-align: left;
    padding: 0;
    margin: 0;
}

.sp-register-button input, .sp-register-button button {
    text-indent: 3px;
}

.sp-register-button button {
    width: 100%;
    overflow: hidden;
}

.sp-register-button select option {
    background: #fff;
    color: #1c1c1c;
}

.color-white {
    color: white;
}

.sp-action-button {
    text-decoration: none;
    background: none;
    border: none;
    cursor: pointer;
}

.sp-action-button:disabled {
    opacity: .6;
}

.sp-app button:disabled {
    opacity: .6;
    background: none;
}

.sp-app #login-form button {
    color: #cecece;
    background: #444;
    opacity: 1;
}

.sp-app .modal {
    background: rgba(0, 0, 0, 0.6);
}

.sp-app .horizontal-center-content {
    margin: 0 auto;
    text-align: center;
}

.sp-app #login-form button:hover {
    opacity: .8;
}

.sp-register-button .sp-white-border {
    border-bottom: 1px dashed white;
}

.sp-app .sp-list div.list-group-item.sp-white-border {
    border-bottom: 1px dashed white;
}

.sp-app label {
    margin-bottom: 0;
}

.sp-app .margin-top-10px {
    margin-top: 10px;
}

.margin-top-0px {
    margin-top: 0px;
}

.sp-register-button .sp-gray-border {
    border-bottom: 1px dashed #cecece;
}

.sp-app .sp-white-dashed-border {
    height: 1px;
    width: 100%;
    margin-bottom: 4px;
    border-top: 1px dashed white;
}

.sp-app .sp-menu .sp-icon {
    color: #C8C8C8;
}

.sp-register-button select:focus, .sp-register-button input:focus, .sp-register-button button:focus {
    box-shadow: none;
}

.sp-register-button ::-webkit-input-placeholder {
    color: white;
}

.sp-register-button :-moz-placeholder { /* Firefox 18- */
    color: white;
}

.sp-register-button ::-moz-placeholder { /* Firefox 19+ */
    color: white;
}

.sp-register-button :-ms-input-placeholder {
    color: white;
}

.sp-register-button:hover {
    animation-name: sp-opacity;
    animation-duration: 0.3s;
    animation-fill-mode: forwards;
}

@keyframes sp-opacity {
    from {
        opacity: 1;
    }
    to {
        opacity: 0.8;
    }
}

.sp-register-button {
    text-decoration: none;
    margin: 0;
    padding: 0;
}

.sp-register-button .input-group-addon {
    background: none;
    border: none;
    min-width: 60px;
}

.col-fixed-200 {
    width: 200px;
    background: #1c1c1c;
    position: fixed;
    height: 100%;
    z-index: 9999;
}

.sp-list .list-group-item .list-group-item-text .sp-courses-list-recertify {
    padding: 0.1em .6em;
    font-size: 14px;
}

.col-courses-fixed-200 {
    width: 200px;
    background: #1c1c1c;

}

.col-offset-200 {
    padding-left: 215px;
}

.vertical-center {
    min-height: 100%; /* Fallback for browsers do NOT support vh unit */
    min-height: 100vh; /* These two lines are counted as one :-)       */
    display: flex;
    align-items: center;
}

.transparent-color {
    background: none;
}

.fill {
    min-height: 100%;
    height: 100%;
}

.row-3 {
    min-height: 33%;
    height: 33%;
}

.vcenter {
    display: inline-block;
    vertical-align: middle;
    float: none;
}

.sp-dark-button {
    background: #444;
}

.sp-dark-button:hover {
    color: #fff;
    opacity: 0.8;
}

.icon-holder {
    width: 50px;
    height: 50px;
    text-align: center;
    line-height: 49px;
}

.sp-dark-grey-text-color {
    color: #1c1c1c !important;
}

.sp-light-grey-text-color {
    color: #cecece;
}

.sp-register-button-text {
    font-size: 24px;
    color: #fff;
}

/*LOGIN*/
#logout {
}

.sp-background {
    background: url('/assets/images/login_background.jpg') no-repeat center;
    position: relative;
}

.sp-background:before {
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
    left: 0;
    right: 0;
}

.white-background {
    background: #fff;
    border-radius: 3px;
}

#login-form .input-group-addon {
    background: #fff;
    border: none;
}

#login-form .top .input-group-addon {
    -webkit-border-radius: 3px 0px 0px 0px !important;
    -moz-border-radius: 3px 0px 0px 0px !important;
    border-radius: 3px 0px 0px 0px !important;
}

#login-form .top .form-control {
    -webkit-border-radius: 0px 3px 0px 0px !important;
    -moz-border-radius: 0px 3px 0px 0px !important;
    border-radius: 0px 3px 0px 0px !important;
}

#login-form .bottom .input-group-addon {
    -webkit-border-radius: 0px 0px 0px 3px !important;
    -moz-border-radius: 0px 0px 0px 3px !important;
    border-radius: 0px 0px 0px 3px !important;
}

#login-form .bottom .form-control {
    -webkit-border-radius: 0px 0px 3px 0px !important;
    -moz-border-radius: 0px 0px 3px 0px !important;
    border-radius: 0px 0px 3px 0px !important;
}

/*.bottom-right-border {*/
/*-webkit-border-radius: 0px 0px 3px 0px!important;*/
/*-moz-border-radius: 0px 0px 3px 0px!important;*/
/*border-radius: 0px 0px 3px 0px!important;*/
/*}*/

#login-form .input-group-addon i {
    color: #444;
}

#login-form .form-control, #login-form .input-group {
    border: none;
    box-shadow: none;
}

#login-form .form-control, #login-form .input-group.remove-radius-bottom {
    border-bottom-left-radius: 0px;
    border-bottom-right-radius: 0px;
}

#login-form .form-control, #login-form .input-group.remove-radius-top {
    border-top-left-radius: 0px;
    border-top-right-radius: 0px;
}

.sellpro-logo {
    background: url('/assets/images/sign_in_logo.png') no-repeat center center;
    background-size: auto;

}

/*LOGIN*/

.nav-tabs > li.active > a, .nav-tabs > li.active > a:focus, .nav-tabs > li.active > a:hover {
    border: 0;
    border-bottom: 3px solid red;
    color: black;
}

.nav-tabs > li > a {
    border-radius: 0;
    font-size: 16pt;
    color: black;
}

.nav-tabs {
    border-bottom: 0;
}

.list-item-description {
    font-size: 12pt;
    color: dimgray;
}

.list-item-title {
    font-size: 14pt;
    color: black;
    font-weight: bold;
}

.list-item {
    border-bottom: 1px solid grey;
    margin: 0;
    display: inline-block;
    padding: 20px 40px;
    width: 100%;
}

.sp-app .list-group-item img {
    width: 60px;
    vertical-align: middle;

}

.sp-app .list-group-item {
    display: inline-block;
    width: 100%;
    overflow: hidden;
}

.sp-app .list-group-item h3 {

    white-space: nowrap;
    text-overflow: ellipsis;
}

.image-holder {
    display: table-cell;
    padding-right: 15px;
}

.content-holder {
    display: table-cell;
    vertical-align: middle;

}

.sp-app .table-row {
    display: table-row;
}

.sp-app .sp-list .list-group-item:first-child {
    border-top: 1px solid #C8C8C8;
    border-radius: 0;
}

.sp-app .sp-list .list-group-item {
    border-bottom: 1px solid #C8C8C8;
    border-radius: 0;
}

.sp-loading {
    float: none;
}

.sp-loader-static {
    width: 149px;
    height: 149px;
    background-image: url("/assets/images/sp.png");
    background-repeat: no-repeat;
    background-position: center center;
    margin: 25% auto 0 auto;
    position: relative;
    z-index: 9999;
}

.sp-loader-static-dark {
    width: 149px;
    height: 149px;
    background-image: url("/assets/images/sp-dark.png");
    background-repeat: no-repeat;
    background-position: center center;
    margin: 0 auto 0 auto;
    position: relative;
    z-index: 9999;
}

@-webkit-keyframes rotating /* Safari and Chrome */
{
    from {
        -ms-transform: rotate(0deg);
        -moz-transform: rotate(0deg);
        -webkit-transform: rotate(0deg);
        -o-transform: rotate(0deg);
        transform: rotate(0deg);
    }
    to {
        -ms-transform: rotate(360deg);
        -moz-transform: rotate(360deg);
        -webkit-transform: rotate(360deg);
        -o-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}

@keyframes rotating {
    from {
        -ms-transform: rotate(0deg);
        -moz-transform: rotate(0deg);
        -webkit-transform: rotate(0deg);
        -o-transform: rotate(0deg);
        transform: rotate(0deg);
    }
    to {
        -ms-transform: rotate(360deg);
        -moz-transform: rotate(360deg);
        -webkit-transform: rotate(360deg);
        -o-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}

.sp-loader-dynamic {
    -webkit-animation: rotating 1s linear infinite;
    -moz-animation: rotating 1s linear infinite;
    -ms-animation: rotating 1s linear infinite;
    -o-animation: rotating 1s linear infinite;
    animation: rotating 1s linear infinite;
}

.rotate {
    -webkit-animation: rotating 1s linear infinite;
    -moz-animation: rotating 1s linear infinite;
    -ms-animation: rotating 1s linear infinite;
    -o-animation: rotating 1s linear infinite;
    animation: rotating 1s linear infinite;
}

.search-box-container {
    width: 100%;
    padding: 5px;
}

.sp-header .sp-icon {
    height: inherit;
    text-align: center;
    padding-right: 20px;
}

.sp-header .sp-icon.sp-search {
    padding-right: 0px;
}

.sp-app h1, .sp-app h3 {
    margin: 10px;
}

.sp-app .vcentered-row {
    display: flex;
    align-items: center;
}

.sp-app .row-4 {
    height: 25%;
    margin-bottom: 0;
}

.sp-app .list-group {
    width: 100%;
}

.row.row-flex {
    display: flex;
    height: 100%;
    flex-direction: column;
}

.row .col-flex-wrap {
    flex: none;
}

.row .col-flex-fill {
    display: flex;
    flex: auto;
    overflow-x: hidden;
}

.row .col-flex-fill-fix {
    flex: auto;
    overflow-x: hidden;
}

.row .flex-center {
    display: flex;
    align-items: center;
    width: 100%;
    margin: auto;
}

.sp-app .flex-fill {
    display: flex;
    align-items: inherit;
    width: 100%;
    margin: 0 auto;
}

.sp-app .list-group-item h3 {
    margin: 0;
}

.sp-app .list-group-item {
    cursor: pointer;
}

.sp-app .list-group-item:hover {
    opacity: .9;
}

#sp-add-store .form-control {
    color: #1c1c1c;
}

#sp-add-store .form-control::-webkit-input-placeholder { /* WebKit, Blink, Edge */
    color: #1c1c1c;
}

#sp-add-store .form-control:-moz-placeholder { /* Mozilla Firefox 4 to 18 */
    color: #1c1c1c;
    opacity: 1;
}

#sp-add-store .form-control::-moz-placeholder { /* Mozilla Firefox 19+ */
    color: #1c1c1c;
    opacity: 1;
}

#sp-add-store .form-control:-ms-input-placeholder { /* Internet Explorer 10-11 */
    color: #1c1c1c;
}

#terms-and-conditions p {
    color: white;
}

#terms-and-conditions strong {
    font-size: 14px;
    font-weight: bold;
}

#terms-and-conditions div {
    color: white;
}

.sp-app .input-group {
    width: 100%;
}

.sp-app .sp-tile-right-content {
    text-align: center;
}

.sp-app .sp-tile-title {
    font-size: 10vh;
    line-height: 10vh;
    font-weight: bold;
    font-family: "OpenSans300", sans-serif;
}

.sp-app .sp-tile-description {
    font-size: 2vh;
    line-height: 2vh;
    font-family: "OpenSans300", sans-serif;
}

.sp-app .sp-tile-icon.sp-proshop {
    margin-left: -4vh !important;
}

.sp-app .sp-tile-icon.sp-tile-events {
    margin-left: 4vh !important;
    margin-right: 0 !important;
}

.flex-column.not-reverse {
    flex-direction: column;
    display: flex;
    align-items: flex-start;
}

.flex-column.reverse {
    flex-direction: column;
    display: flex;
    align-items: flex-end;
}

.sp-app .sp-tile-icon.sp-tile-events.reverse:before {
    -moz-transform: scale(-1, 1);
    -webkit-transform: scale(-1, 1);
    -o-transform: scale(-1, 1);
    -ms-transform: scale(-1, 1);
    transform: scale(-1, 1);
}

.sp-app .sp-tile-icon {
    font-size: 16vh;
    line-height: 16vh;
    margin-right: 4vh;
}

.sp-app .sp-tile-icon.small {
    margin-right: 0;
}

.sp-app .tile {
    border-top: 4px solid transparent;
    border-right: 4px solid transparent;
}

.sp-app .tile.last {
    border-bottom: 4px solid transparent;
}

.sp-app .sp-tile-icon.sp-mall.reverse {
    margin-right: -5.5vh !important;
    margin-left: 7vh;
}

.sp-app .col-flex-fill.reverse {
    flex-direction: row-reverse !important;
}

.sp-app .tile.left {
    border-left: 4px solid transparent;
}

.padding-0 {
    padding: 0;
}

.margin-0 {
    padding: 0;
}

.sp-app video, .sp-app iframe, .sp-app object, .sp-app embed {
    width: inherit;
    height: inherit;
    border: none;
}

video::-internal-media-controls-download-button {
    display: none;
}

video::-webkit-media-controls-enclosure {
    overflow: hidden;
}

video::-webkit-media-controls-panel {
    width: calc(100% + 30px);
}

.sp-app .topic-list-item-header .sp-icon {
    padding: 0 10px 0 10px;
}

.sp-app .trim-text {
    white-space: nowrap;
    text-overflow: ellipsis;
}

.margin-left-10px {
    margin-left: 10px;
}

.margin-right-10px {
    margin-right: 10px;
}

.sp-app h2 {
    margin-top: 10px;
}

.flex-column {

    flex-direction: column;
}

.profile-info {
    padding-bottom: 20px;
    border-bottom: 1px solid #cecece;
}

.profile-info button {
    padding: 0;
}

.profile-info img {
    margin-right: 20px;
    width: 74px;
}

.profile-column .icon .sp-icon {
    padding-right: 0;
}

.profile-column {
    padding: 20px 0;
    border-bottom: 1px solid #cecece;
}

@media (min-width: 992px){
    .profile-column-border-right {
        border-right: 1px solid #cecece;
    }

    .profile-column.profile-column-border-bottom-none {
        border-bottom: none;
    }
}

.profile-column .icon {
    width: 90px;
    color: #1c1c1c;
    text-align: center;
    margin: 5px;
}

.list-group-item-text {
    margin: 0;
}

.sp-app .nav-tabs > li > a {
    font-size: 18px;
}

.sp-app .margin-bottom-20 {
    margin-bottom: 20px;
}

.sp-app .margin-top-20 {
    margin-top: 20px;
}

.sp-app .margin-right-20 {
    margin-right: 20px;
}

.sp-app .quiz-pagination {
    display: inline-block;
    display: inline-block;
    margin: 20px auto;
    text-align: center;
}

.sp-app .quiz-pagination li {

    list-style-type: none;
    width: 30px;
    height: 5px;
    background: #C8C8C8;
    display: inline-block;

}

.sp-app #certificate {
    background: no-repeat center;
    background-size: contain;
}

.sp-app #login .sp-dropdown {
    position: relative;
}

.sp-app #login .sp-dropdown button:hover {
    opacity: 0.6;
}

.sp-app .dropdown-menu {
    right: 0;
    left: auto;
    color: #cecece;
    background: #353535;
    width: 100%;
    display: none;
}

.sp-app .dropdown-menu li a {
    color: #cecece;
}

.sp-app .dropdown-menu li a:hover {
    background: #444;
    color: #fff;
}

.sp-app .dropdown-menu.visible {
    display: block;
}

.col-centered {
    margin: auto;
}

#language-dropdown {
    width: 100%;
    margin: 0 0 45% 0;
    cursor: hand;
}

.lang_flag_img {
    width: 30px;
    height: auto;
    flex: none;
}

li.lang_flag {
    float: left;
    width: 100%;
}

.image_arrow {
    width: 15px;
    flex: none;
}

.sp-app #login .sp-dropdown .btn#language-button {
    color: #cecece;
    background: #444;
    margin: 0px;
    padding: 6px 12px;
    border-color: transparent;
    opacity: 1;

}

.sp-app #login .sp-dropdown .btn#language-button div {
    display: flex;
    justify-content: center;
    align-items: center;

}

.sp-app #login .sp-dropdown .btn#language-button div > span {
    flex: auto;
    text-align: left;
    padding-left: 10px;
}

/*START EVENTS*/

.event-day-time-seats-holder {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.event-row-holder {
    display: flex;
    align-items: center;
}

.event-content-holder {
    display: table-cell;
    vertical-align: middle;

}

.event-brand-img-holder {
    padding-right: 30px;
}

.event-date-time-text {
    font-size: 14px;
    text-transform: uppercase;
}

.event-name-text {
    font-size: 18px;
}

.margins {
    margin-top: 150px;
    margin-bottom: 50px;
}

.padding-top-bottom-30 {
    padding-top: 30px;
    padding-bottom: 30px;
}

.sp-app .event-time {
    line-height: 15px;
    font-size: 20px;
    color: dimgray;
}

.btn-events-search {
    color: #1c1c1c;
    text-decoration: none;
}

.btn-events-search:hover {
    color: #1c1c1c;
    text-decoration: none;
}

.font-open-sans-bold {
    font-family: OpenSansBold;
}

#event_details hr {
    border-top: 1px solid #ccc;
    width: 100%;
    margin-top: 10px;
    margin-bottom: 10px;
}

#event_details .event-details-inner-wrapper {
    align-items: flex-start;
    display: flex;
    flex-direction: column;
}

#event_details .event-details-fragment-with-icon i {
    margin: 5px 10px 5px 0;
    color: #929292;
}

#event_details .event-details-fragment-with-icon p {
    display: inline-flex;
    align-items: center;
}

#event_details .event-details-fragment-with-icon p.event-details-field-data {
    margin-left: 30px;
}

#event_details .event-details-fragment-with-icon {
    display: flex;
    flex-direction: column;
    width: 100%;
    justify-content: flex-start;
    text-align: left;
}

#event_details .event-details-fragment {
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: flex-start;
}

#event_details .event-details-attachments-fragment-wrapper {
    width: 100%;
    text-align: left;
}

#event_details .event-details-attachments-fragment-list-item {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 0;
}

#event_details .event-details-attachments-fragment-list-item-name {
    flex: 2;
}

#event_details .event-details-fragment .event-details-label, #event_details .event-details-fragment-with-icon .event-details-label, #event_details .event-details-fragment .event-details-field-data, #event_details .event-details-fragment-with-icon .event-details-field-data {
    text-align: left;
    padding: 0 10px;
    margin-bottom: 0;
}

#event_details .event-details-label {
    min-width: 130px;
    color: #929292;
}

#event_details .event-brand-logo, .event-title-text {
    align-self: center;
}

#qrcode {
    margin: 0 auto;
}

#event_details .event-details-fragment .event-details-qr-code-text {
    margin: auto;
    padding: 5px;
}

#event_details .event-details-countdown-fragment {
    display: flex;
    flex-direction: column;
    width: 100%;
}

#event_details .event-details-countdown-fragment .event-details-label {
    font-size: 19px;
    font-weight: 100;
}

#event_details .event-details-countdown-fragment .event-details-field-timer {
    font-size: 37px;
}


#event_details .attendance-code-form-wrapper {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

#event_details .attendance-code-form-wrapper .attendance-code-form{
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding: 15px;
    margin: 15px;
    background: #F0F0F0;
    width: 100%;
    border-radius: 10px;
    border: #c1c1c1 solid 1px;
}


#event_details .attendance-code-form-wrapper .attendance-code-form .title-wrapper{
    width: 100%;
    text-align: left;
    margin-bottom: 10px;
}
#event_details .attendance-code-form-wrapper .attendance-code-form .form-elements-wrapper{
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: row;
}

#event_details .attendance-code-form-wrapper .attendance-code-form .form-elements-wrapper{
    display: flex;
    align-items: center;
    justify-content: stretch;
    flex-direction: row;
    width: 100%;
}

#event_details .attendance-code-form-wrapper .attendance-code-form .form-elements-wrapper .input-wrapper{
    margin-right: 10px;
    width: 100%;
}

#event_details .attendance-code-form-wrapper .attendance-code-form .form-elements-wrapper .input-wrapper input{
    border: #c1c1c1 solid 1px;
    border-radius: 4px;
    min-height: 40px;
    width: 100%;
}

#event_details .attendance-code-form-wrapper .attendance-code-form .form-elements-wrapper .button-wrapper button{
    background: black;
    color: white;
    padding: 8px;
    border-radius: 8px;
    width: 90px;
}

/*END EVENTS*/

@media print {
    body * {
        visibility: hidden;
    }

    #no-print-area * {
        display: none;
    }

    #event_details * {
        visibility: visible;
    }

    #event_details {
        position: absolute;
        top: 0px;
        left: 0px;
        width: 100%;
        height: 100%;
        margin: 0 auto;
    }

    #event_details .event-details-inner-wrapper {
        margin: 0 auto;
    }

}

/*topic quize*/

.topic-quiz-teaser .topic-quiz-teaser-background {
    background-color: #000000;
}

.topic-quiz-teaser .topic-quiz-teaser-title {
    color: #ffffff;
    font-size: 27px;
    text-align: center;
}

.topic-quiz-teaser .topic-quiz-teaser-name {
    color: #ffffff;
    font-size: 25px;
    word-wrap: break-word;
    text-align: center;
}

.topic-quiz-teaser .topic-quiz-teaser-submit-button {
    color: #ffffff;
    width: auto;
    font-size: 38px;
    margin: 33% auto 0 auto;
}

.topic-quiz-teaser .topic-quiz-teaser-question-name, .topic-quiz-teaser .topic-quiz-teaser-question-hint {
    color: #ffffff;
}

.topic-quiz-teaser .topic-quiz-teaser-question-hint {
    color: rgba(255, 255, 255, 0.75);
    font-size: 16px;
    text-align: left;
    margin-left: 20px;
    margin-top: 20px;
}

.topic-quiz-teaser .topic-quiz-teaser-question-name {
    word-wrap: break-word;
    font-size: 22px;
    text-align: center;
}

.topic-quiz-teaser .topic-quiz-teaser-image {
    margin: 0 auto;
}

.topic-quiz-teaser .topic-quiz-teaser-answer-list-item:hover {
    background-color: lightgray !important;
    color: #333 !important;
}

.topic-quiz-teaser .topic-quiz-answer-selected {
    background-color: lightgray !important;
    color: #333 !important;
}

.topic-quiz-teaser .topic-quiz-answer-unselected {
    background-color: #000000 !important;
    color: #ffffff !important;
}

.topic-quiz-teaser .topic-quiz-teaser-answer-list-item {
    background-color: #000000;
    padding-left: 30px;
    padding-bottom: 10px;
    padding-top: 12px;
    cursor: pointer;
    color: #ffffff;
    font-size: 19px;
}

.topic-quiz-teaser .topic-quiz-teaser-answer-text {
    color: #ffffff;
    font-size: 14px;
    word-wrap: break-word;
    text-align: center;
    margin-top: 30px;
}

.surveys-list .list-flex-row {
    display: flex;
    flex-direction: row;
    justify-items: center;
    align-items: center;
    justify-content: flex-start;
}

.surveys-list .icon-holder {
    display: flex;
    flex: 1;
    justify-content: flex-end;
}

.survey-upload-wrapper .dropzone {
    min-height: 400px;
    min-width: 400px;
    display: table;
    width: 100%;
    background-color: #eee;
    border: dotted 1px #aaa;
    cursor: hand;
}

.survey-upload-wrapper .text-wrapper {
    display: table-cell;
    vertical-align: middle;
}

.survey-upload-wrapper .centered {
    font-family: sans-serif;
    font-size: 1.3em;
    font-weight: bold;
    text-align: center;
}

.survey-upload-wrapper {
    padding: 10px;
}

.survey-upload-wrapper .uploaded-images-wrapper {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    margin: 5px 0;
    width: 100%;
    height: 40%;
}

.survey-upload-wrapper .uploaded-images-wrapper img {
    margin: 5px;
    max-width: 600px;
    max-height: 600px;
}

.survey-rate-wrapper {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-content: center;
}

.survey-rate-wrapper i {
    cursor: pointer;
}

.terms-and-conditions-wrapper {
    background-color: #f7f7f7;
}

/*======= Very Large devices ========*/
@media (min-width: 1440px) {
    .topic-quiz-teaser .topic-quiz-teaser-submit-button {
        font-size: 50px;
    }
}

/*======= Large devices ========*/
@media (min-width: 1201px) {
    .topic-quiz-teaser .topic-quiz-teaser-submit-button {
        font-size: 40px;
    }
}

/*======= Small devices ========*/
@media (max-width: 1024px) {
    .topic-quiz-teaser .topic-quiz-teaser-submit-button {
        font-size: 25px;
    }

    .topic-quiz-teaser .topic-quiz-teaser-title {
        font-size: 22px;
    }

    .topic-quiz-teaser .topic-quiz-teaser-name {
        font-size: 20px;
    }

    .topic-quiz-teaser .topic-quiz-teaser-question-name {
        font-size: 17px;
    }
}

/*======= Very Small devices ========*/
@media (max-width: 768px) {
    .topic-quiz-teaser .topic-quiz-teaser-submit-button {
        font-size: 22px;
    }

    .topic-quiz-teaser .topic-quiz-teaser-title {
        font-size: 20px;
    }

    .topic-quiz-teaser .topic-quiz-teaser-name {
        font-size: 18px;
    }

    .topic-quiz-teaser .topic-quiz-teaser-question-name {
        font-size: 14px;
    }
}

.square-check-box {
    display: flex;
    align-items: center;
    height: 45px;
    cursor: pointer;
    font-size: 14px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

.square-check-box .checkmark {
    height: 14px;
    width: 14px;
    border: #1c1c1c 1px solid;
    background-color: transparent;
    margin-right: 20px;
}

.square-check-box .checkmark-white {
    border: lightgray 1px solid !important;
}

.topic-quiz-answer-unselected:hover .checkmark-white {
    border: #1c1c1c 1px solid !important;
}

.square-check-box .checkmark.checked {
    background-color: black;
}

.question-hint {
    margin-top: 20px;
    margin-left: 30px;
    margin-bottom: 10px;
    color: rgba(29, 29, 29, 0.71);
    font-size: 20px;
}

.register-link-button {
    flex: 1;
}

.external-link-button, external-link-button:hover {
    color: #337ab7 !important;
}

.sp-topic-icon {
    color: #929292 !important;
}

#pick-store-from-google-map-wrapper {
    justify-content: center;
    height: 80%;
    padding: 60px 0;
    width: 100%;
}

.search-query-field{
    margin-top: 10px;
}

.h-100{
    height: 100%;
}


.sp-color-red{
    color: #FF6666;
}