.reference-toolbox-detail i {
    padding: 10px;
    margin-right: 10px;

}

.sp-app .sp-list .list-group-item.remove-border {
    border: none;
}

.sp-action-button.pull-right.color-right.vcenter.done-button {
    margin-top: 0px;
    margin-right: 15px;
}

.feedback-textarea {
    width: 100%;
    height: 150px;
    border: 2px solid grey;
}


.form-control.sp-register-button-text.input-email-color {
    color: black;
    border-bottom: 1px solid grey;
}

.sp-red-text-color {
    color: red;
}

.clickable {
    cursor:pointer;
}

.sp-app .list-group-item.not-clickable {
    cursor:default;
}

.sp-content-top-margin{
    margin-top: 30px;
}
.sp-app .list-group.sp-list .list-group-item.selected {
    background: #cecece;
}