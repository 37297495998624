/*register*/
.container-fluid-registration {
    background-color: rgb(219, 75, 80) !important;
}

.register-gradient {
    background: linear-gradient(rgba(219, 75, 80, 0.8), rgba(41, 19, 17, 1));
}

#terms-and-conditions-iframe{
    background: #F7F7F7;
}

/*register*/

/*Country*/

.country-background-icons {
    background-color: transparent;
    border: none;
    padding-bottom: 7%;
}

.country-text-header {
    text-align: center;
}

.country-dotted-horizontal-line {
    margin-top: 3%;
    border-top: dotted 1px;
}

.country-textbox-noshadow {
    box-shadow: none !important;
    border-width: 0;
    background-color: transparent;
    -webkit-box-shadow: none;
    color: white;
}

.country-option-color option {
    background-color: transparent !important;
    color: white !important;
}

/*Country*/

/*Employer*/
.employer-dotted-horizontal-line {
    margin-top: 2%;
    border-top: dotted 1px;
}

.employer-textbox-noshadow {
    box-shadow: none !important;
    border-width: 0;
    background-color: transparent;
    -webkit-box-shadow: none;
    color: black;
}

/*Employer*/
/*Profile*/
.profile-dotted-horizontal-line {
    margin-top: 6%;
    border-top: dotted 1px;
}

.profile-textbox-noshadow {
    box-shadow: none !important;
    border-width: 0;
    background-color: transparent;
    -webkit-box-shadow: none;
    color: black;
}

/*Profile*/

/*Preferences*/
label {
    font-size: 18px;
    width: 100%;
    text-align: left;
}

input[type=checkbox] {
    display: none;
}

input[type=checkbox] + label:after {
    font-family: icomoon;
    display: inline-block;
}

input[type=checkbox] + label:after {
    content: "\W";
}

input[type=checkbox] + label:after {
    vertical-align: middle;
    float: right;
}

input[type=checkbox]:checked + label:after {

    content: "\V";
}

input[type=checkbox]:checked + label:after {
}

/*Preferences*/

/* Terms & conditions*/
.terms-header {
    height: 10%;
}

/* Terms & conditions*/

/*main/Download*/
.main_download_page {
    width: 80%;
    text-align: center;
    top: 50%;

    left: 20%;
}

/*main/download*/

/*retailer_home*/
.home_section {
    background-color: white;
}

.sellpro_logo_home_brand {
    top: 40%;
    width: 300px;
    left: 40%;
    margin-left: -100px;
    margin-top: -50px;
    position: absolute;
}

.sellpro_logo_home {
    top: 50%;
    width: 300px;
    left: 51%;
    margin-left: -100px;
    margin-top: -50px;
    position: absolute;

}

.sellpro_home_box {
    height: 100%;

}

.placeholder_change_password input::-webkit-input-placeholder {
    color: black;
    border-bottom: 1px solid grey;
}

.cursor_home {
    cursor: pointer
}

/*home*/
/*brand home*/
.sellpro_brand_home_text_center {
    text-align: center;
    padding: 40px 20px;
}

.sellpro_brand_home_icons {
    padding: 50px 0px 0px 0px;

}

.sellpro_brand_home_text_padding {
    padding: 60px;
    font-size: 20px
}

.sellpro_play_zone_retailer {
    text-align: center;
    padding: 40px;
}

.sellpro_home_in_progress {
    padding: 35px
}

.sellpro_home_in_progress_number {
    font-size: 50px;
    padding: 60px
}

.sellpro_home_in_progress_link {
    padding: 60px;
    font-size: 20px;
}

.home-text-left {
    float: left;
}

.home-links-text {
    font-size: 20px
}

.profile-link-color-home {
    color: black;
}

.home-text-right {
    float: right;
}

.home-text-paragraphs {
    font-weight: bold;
    padding-left: 30px;
}

.home-text-details {
    color: rgb(74, 196, 248);
    font-size: 16px;
}

.home-item-details {
    position: absolute;
    bottom: 4px;
}

.home-list-items {
    display: inline;;
}

.home-text-paragrah-brand {
    font-weight: bold;
    padding-left: 100px;
}

.home-text-details-brand {
    color: rgb(74, 196, 248);
    padding-left: 100px;
}

.home-share-brand {
    font-size: 20px;
}

.profile-information-brand {
    margin-top: 50px
}

.profile-information-bottom-brand {
    height: 100%;
    border-bottom: 1px solid black;
}

.profile-information-text-brand {
    margin-left: 50px
}

.profile-information-img-brand {
    border-bottom: 1px solid black;
    height: 8%;
}

.profile-information-image-brand {
    width: inherit;
}

.profile-information-cert-link-brand {
    color: black;
    text-decoration: none;
}

.profile-information-text-cert-brand {
    color: black;
    padding-left: 10px;
    text-decoration: none;
    position: absolute;
    margin-top: 12px;
    font-size: 30px;
}

.profile-information-right-retailer {
    border-right: 1px solid black;
}

.profile-information-text-company-retailer {
    color: black;
    padding-left: 10px;
    text-decoration: none;
    position: absolute;
    margin-top: 25px;
}

.profile-information-text-store-retailer {
    color: black;
    padding-left: 10px;
    text-decoration: none;
    position: absolute;
}

.profile-information-bottom-top-border-retailer {
    border-bottom: 1px solid black;
    border-top: 1px solid black
}

.profile-information-text-product-retailer {
    color: black;
    padding-left: 10px;
    text-decoration: none;
    position: absolute;
    margin-top: 25px;
}

.profile-information-text-cert-retailer {
    color: black;
    padding-left: 10px;
    text-decoration: none;
    position: absolute;
    margin-top: 25px;
}

.profile-information-link-cert-retailer {
    color: black;
    text-decoration: none;
}

.loading-component-center-fix {
    position: fixed;
    top: 50%;
    left: 50%;
}

.loading-component-center-profile {
    position: fixed;
    top: 50%;
    left: 50%;
}

.loading-component-center {
    position: fixed;
    top: 50%;
    left: 50%;
}

.tile-left {
    margin-left: 2px;
}

.tile-right {
    margin-right: 2px;
}

.tile-top {
    margin-top: 2px;
}

.tile-bottom {
    margin-bottom: 2px;
}

.display-width-100 {
    width: 100%;
}

.home-text-name {
    font-size: 20px;
}

.margin-20 {
    margin: 20px 0;
}

.padding-20 {
    padding: 20px;
}

.padding-10 {
    padding: 10px;
}
